import * as React from "react";
import * as styles from "./shipping-policy.module.css";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const ShippingPolicy: FunctionComponent = () => {

  return (
    <Layout>
      <div className={styles.shippingPolicy}>
     
        <div className={styles.docTemplate}>
          <div className={styles.textColumn}>
            <div className={styles.title}>
              <b className={styles.display}>Shipping & Refund Policy</b>
            </div>
       
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>How much does shipping cost?</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  We charge a flat $15 shipping fee on orders valued under $500. We offer free shipping on all orders over $500.
                </div>
              </div>

            </div>
            <div className={styles.subtitleLarge}>
              <div className={styles.display1}>What countries do you ship to?</div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                We ship worldwide. There are some restrictions that sometimes occur intermittently with some countries due to political restrictions. 
                </div>
              </div>
            </div>
            
          <div className={styles.subtitleLarge}>
              <div className={styles.display1}>When will my order arrive?</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  We will strive to get your order in your hands within 14 days. Sometimes delays can be caused by:
                </div>
                 
              </div>
               <ol className={styles.callPhoneNumberOrAlertU}>
                <li className={styles.callPhoneNumber}>
                  Your order forms are incomplete, or you have not sent in your release form and prescription.</li>
                <li className={styles.callPhoneNumber}>
                  Your order forms are illegible.
                </li>
                <li className={styles.callPhoneNumber}>
                 Your credit card information is inaccurate or incomplete. When filling out forms, complete each field in the online order form. Be sure to enter your credit card number, and, expiration date.
                </li>
                <li className={styles.callPhoneNumber}>
                  If you are sending your forms by mail, please ensure that you have signed the forms.
                </li>
                    <li className={styles.callPhoneNumber}>
                  Credit card companies will sometimes deny the purchase, because it is from a foreign company. Sometimes as a security measure, card companies will deny purchases made in a foreign country if you haven’t traveled there recently.
                </li>
                    <li className={styles.callPhoneNumber}>
                 Contact your credit card company prior to ordering and have them remove all international blocks on the account.
                </li>
                    <li className={styles.callPhoneNumber}>
                 Your credit card is declined for insufficient funds. Be sure that you have sufficient funds to pay for your entire order to be processed.
                </li>
                <li className={styles.callPhoneNumber}>
                  If you choose to pay by certified check, or US money order, it will take longer to process. These forms of payment will require several more days to clear our financial system.
                </li>
                <li className={styles.callPhoneNumber}>
                  There are sometimes unforeseen delays when it comes to shipping internationally. Peak periods at holiday time, and the effects of extreme weather can all have an impact on shipping times. Please order early enough to ensure that you always have a supply on hand.
                </li>
              </ol>
            </div>
  
            <div className={styles.subtitle2}>
              <div className={styles.display4}>What happens if something goes wrong with my order?</div>
            </div>
            <div className={styles.textbody6}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  <p className={styles.orderAmount}>
                  In the event that your order does not arrive within 28 days of receiving a confirmation of sent order, we will re-ship the order at our expense. In the event that both orders arrive consider it our gift to you.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                 Example:
                </div>
              </div>
              <div className={styles.p20}>
                <div className={styles.caption}>
                  <ul className={styles.callPhoneNumberOrAlertU}>
                    <li className={styles.callPhoneNumber}>
                      Order placed and paid for.
                    </li>
                    <li className={styles.callPhoneNumber}>
                       28 business days after your confirmation email has been received and the order has not been delivered we will re-ship at our expense.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
                 <div className={styles.textbody}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                 There are however some exceptions:
                </div>
              </div>
              <div className={styles.p20}>
                <div className={styles.caption}>
                  <ul className={styles.callPhoneNumberOrAlertU}>
                    <li className={styles.callPhoneNumber}>
                      At the time the order was placed, incorrect shipping information was provided.
                    </li>
                    <li className={styles.callPhoneNumber}>
                      Delivery was refused.
                    </li>
                       <li className={styles.callPhoneNumber}>
                     Package was returned to postal outlet for pickup.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>What if I need to cancel an order?</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  If you need to cancel an order, you can do so without charges or penalties up until your order is prepared. Please attempt to cancel your order ASAP to avoid any orders being sent out. You cannot cancel an order or request a refund once the order has shipped.
                </div>
              </div>
            </div>

            <div className={styles.subtitle2}>
              <div className={styles.display4}>Which orders qualify for a refund?</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                 In the unlikely event you are unhappy with the order you received, please contact us. We will only issue refunds in the following circumstances: if we misrepresented the product packaging to you when you placed your order; if you received a damaged package; if the product is proven to be inactive when you initially use it.
                </div>
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
               In any of these cases, you should hold the products until you receive further instructions from us. If we determine you should receive a refund, you can either request that we send a replacement or you can receive a full refund or account credit for that item.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>What information do I need to return a product?</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption30}>
                 We do not accept product returns. Do not hesitate to contact the customer service for guidance. If you had used the medical device and found it faulty in the process, keep the lot number and expiry date information in the client’s file.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>How do I get a refund?</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                  Depending on the circumstances, you may be able to receive a refund under our instructions. You can either request we send a replacement to the same shipping address as the original or receive a full refund or account credit for that item. This account credit is applicable toward any future purchase of the same or a different product.
                </div>
              </div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                 Please note that any product defects/issues must be reported within 6 months from the date of purchase in order to receive a replacement or refund. We will not replace or refund any items with issues that are brought to our attention after this period ends.
                </div>
              </div>
            </div>
            <div className={styles.subtitle2}>
              <div className={styles.display4}>Will I always qualify for a refund?</div>
            </div>
            <div className={styles.textbody4}>
              <div className={styles.p1}>
                <div className={styles.caption}>
                We offer refunds at our own discretion. We cannot guarantee we will offer a refund in all circumstances. However, we do our best to ensure you will be happy with your medical supply order. If you have any questions or concerns about this policy, contact us for more information.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default ShippingPolicy;

export const Head = () => (
  <SEO title="CDI Health | Shipping policy" description="Shipping policy" />
)
